export default {
    translation: {
        'app.scree.recipe-alternatives': 'Recepto alternatyvos',
        'app.screen.shopping-list': 'Pirkiniai',
        'app.screen.foot-at-home': 'Maistas namuose',
        'app.screen.profile': 'Profilis',
        'app.screen.profile.food-preferences': 'Maisto pageidavimai',
        'app.screen.profile.language': 'Kalba',
        'app.screen.profile.share-app': 'Dalintis programėle',
        'app.screen.recipeview.share-recipe': 'Pasidalink receptu',
        'app.screen.recipeview.ingredients-into-shopping-list': 'Sudėti į pirkinių sąrašą',
        'app.screen.recipeview.all-ingredients-in-shopping-list': 'Visi produktai krepšelyje',
        'app.screen.recipeview.missing-ingredients-in-home-list': "Nepavyko rasti recepto, kuriame būtų ingredientų iš jūsų namų sąrašo, bet siūlome išbandyti šį receptą",
        'app.screen.profile_suggested_recipes': 'Paskutinis pasiūlytas receptas',
        'app.screen.profile.give-us-feedback': 'Palikite mums atsiliepimą',
        'app.screen.profile.share-your-recipe': 'Pasidalinkite savo receptu',

        'app.screen.favoritelist.pageTitle': 'Jūsų mėgstamiausi receptai',
        'app.screen.favoritelist.empty-list': 'Dar nepasirinkote jokių mėgstamų receptų.',

        'appmenu.home': 'Pradžia',
        'appmenu.food-at-home': 'Maistas namuose',
        'appmenu.shopping-list': 'Pirkiniai',
        'appmenu.profile': 'Profilis',

        'empty shopping list': 'Jūsų pirkinių sąrašas tuščias.',
        'Do not ask if home food is empty': 'Neklaust, jei namų maisto sąrašas tuščias',
        'Copy and share the link below:': 'Nukopijuokite ir pasidalinkite nuoroda žemiau:',
        'What are we eating today?': 'Ką šiandien valgome?',
        'Make menu for a week': 'Sudarykite meniu savaitei',
        'Recipes for inspiration': 'Receptai įkvėpimui',
        'Have at home': 'Namuose turiu',
        'Back': 'Atgal',
        'breakfast': 'Pusryčiai',
        'brunch': 'Priešpiečiai',
        'lunch': 'Priešpiečiai',
        'dinner': 'Pietūs/Vakarienė',
        'serving quantity': 'Porcijų skaičius',
        'serving time': 'Gaminimo laikas',
        'ingredients': 'Reikalingi produktai',
        'cooking steps': 'Gaminimo eiga',
        'Alternatives': 'Alternatyvos',
        'ingredients you have at home': 'turimi ingridientai',

        'main_course': 'Pagrindinis patiekalas',
        'soup': 'Sriuba',
        'dessert': 'Desertas',

        //irene
        'Select': 'Pasirinkti',
        'Close': 'Uždaryti',
        'Enter an item': 'Įveskite daiktą',
        'all ingredients': 'Visi ingridientai',
        'items': 'daiktai',
        'Check all': 'Pažymėti visus',
        'Uncheck all': 'Atžymėti visus',
        'Delete checked': 'Ištrinti pažymėtus',
        'Edit': 'Redaguoti',
        'Save Changes': 'Išsaugoti Pakeitimus',
        'Cancel': 'Atšaukti',
        'Select Language': 'Pasirinkti Kalbą',

        'fooditem.salmon': 'Lašiša',
        'fooditem.honey': 'Medus',
        'fooditem.mustard': 'Garstyčios',
        'fooditem.lemon_juice': 'Citrinos sultys',
        'fooditem.potato': 'Bulvės',
        'fooditem.broccoli': 'Brokolis',
        'fooditem.salt': 'Druska',
        'fooditem.ground_pepper': 'Malti pipirai',
        'fooditem.cherry_tomatoes': 'Vyšniniai pomidorai',
        'fooditem.butter_or_oil_for_frying': 'Sviestas/aliejaus kepimui',
        'fooditem.white_bread': 'Batonas',
        'fooditem.egg': 'Kiaušiniai',
        'fooditem.milk': 'Pienas',
        'fooditem.vanilla_sugar': 'Vanilinis cukrus',
        'fooditem.jam': 'Uogienė',
        'fooditem.broth': 'Sultinys',
        'fooditem.beetroot': 'Raudonas burokėlis',
        'fooditem.carrot': 'Morka',
        'fooditem.cabbage': 'Kopūstas',
        'fooditem.paprika': 'Paprika',
        'fooditem.onion': 'Svogūnas',
        'fooditem.tomato': 'Pomidorai',
        'fooditem.parsley': 'Petražolės',
        'fooditem.wine_vinegar': 'Vyno actas',

        'vegan': 'Veganas',
        'vegetarian': 'Vegetaras',
        'gluten-free': 'Be glitimo',
        'keto': 'Keto',
        'dairy-free': 'Be pieno',
        'nut-free': 'Be riešutų',

        'birthday': 'Gimtadienis',
        'christmas': 'Kalėdos',
        'valentines-day': 'Valentino diena',

        'spicy': 'Spicy',
        'sweet': 'Saldus',
        'easter': 'Velykos',

        'easy': 'lengva',
        'medium': 'Medium',
        'hard': 'Hard',

        'level': 'Level',

        'Update weekly menu': 'Atnaujinkite savaitės meniu',

        'Your favorite recipes': 'Jūsų mėgstamiausi receptai',
        'add as favorite': 'pridėti prie mėgstamiausių',
        'Remove favorite': 'Pašalinti mėgstamiausią',

    }
}

import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import mixpanel from 'mixpanel-browser';

import { findRandomRecipe, createNewWeekMenu } from "../util/api"
import { findTodayRecipe } from "../util/recipe"
import { loadHomeIngridients, loadFoodCategories, saveSuggestedRecipes, getWeekRecipes, saveWeekRecipes } from '../util/storage'

import './Home.css'

import logo from '../images/logo.png'

export default function Main() {
    const navigate = useNavigate();
    const { t } = useTranslation();

    // const findRecipe = async () => {
    //     const homeIngredients = loadHomeIngridients()
    //     const foodCategories = loadFoodCategories()
    //     const idForCategories = await Promise.all( 
    //         foodCategories.map( async category => {
    //             const recipies = await findRandomRecipe(homeIngredients, category)
    //             if (recipies) {
    //                 return recipies.id
    //             }
    //             return null
    //         })
    //     )
    //     saveSuggestedRecipes(idForCategories.filter(id => id))
    //     const ids = idForCategories.filter(id => id).join(',')
    //     navigate(`/recipe-view/${ids}`)
    // }

    const today = async () => {
        mixpanel.track('Home-today recipe')
        const url = await findTodayRecipe()
        navigate(url)
    }

    const weekMenu = async () => {
        mixpanel.track('Home-week')
        const homeIngredients = loadHomeIngridients()

        var recipeIds = getWeekRecipes()
//        var recipeIds = []
        if(recipeIds.length == 0) {
            recipeIds = await createNewWeekMenu(7)
            saveWeekRecipes(recipeIds)
        }

        const weekRecipes = await Promise.all( recipeIds )
        navigate(`/week-recipies`, { state: {recipies: weekRecipes} })
    }

    const exploreRecipies = () => {
        mixpanel.track('Home-explore recipes')
        navigate(`/recipe-alternatives`)
    }

    const manageFavorites = () => {
         mixpanel.track('Home-manage favorite-recipes')
         navigate(`/favorite-recipies`)
    }
   
    return (
        <>
            <div className='app-logo'>
                <div>
                    <img style={{maxWidth: '200px'}} src={logo}  />
                </div>
            </div>
            <div className="home-content">
                <div style={{flexGrow: 1}}></div>
                <div className="button-language" onClick={()=>today()}>
                    <a>{t('What are we eating today?')}</a>
                </div>
                <div className="button-language" onClick={()=>weekMenu()}>
                    <a>{t('Make menu for a week')}</a>
                </div>
                <div className="button-language" onClick={()=>exploreRecipies()}>
                    <a>{t('Recipes for inspiration')}</a>
                </div>
                <div className="button-language" onClick={()=>manageFavorites()}>
                    <a>{t('Your favorite recipes')}</a>
                </div>
                <div style={{flexGrow: 1}}></div>
            </div>
        </>
    )
}